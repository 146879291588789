<template>
  <b-col
    :md="gridWidth"
    sm="12"
  >
    <b-spinner
      v-if="finalQuestion === null"
      variant="secondary"
    />
    <div v-else>
      <title-input-step
        :title="titleStyle === 'large' ? finalQuestion.questionText : null"
      >
        <validation-provider
          #default="{ errors }"
          :name="finalQuestion.questionText.toLowerCase()"
          :rules="validationRules"
        >
          <b-form-group
            :label="titleStyle === 'small' ? finalQuestion.questionText : null"
          >
            <b-form-input
              v-if="questionRenderType === 'text-field'"
              v-model="answer"
              :state="errors.length > 0 ? false : null"
              rules="required"
            />
            <b-form-radio-group
              v-else-if="questionRenderType === 'radio-group'"
            >
              <b-form-radio
                v-for="(option, index) in finalQuestion.responseConfig.validResponses"
                :key="index"
                v-model="answer"
                :name="finalQuestion.uniqueId"
                :value="option.value"
                class="mb-1"
              >
                {{ option.displayText }}
              </b-form-radio>
            </b-form-radio-group>
            <v-select
              v-else-if="questionRenderType === 'picker'"
              v-model="answer"
              label="displayText"
              :multiple="finalQuestion.responseConfig.allowMultipleResponses"
              :reduce="option => option.value"
              :options="finalQuestion.responseConfig.validResponses"
            />
          </b-form-group>

          <small
            v-if="errors.length > 0"
            class="text-danger"
          >
            {{ errors[0] }}
          </small>
        </validation-provider>
      </title-input-step>

      <b-alert
        v-if="apiError !== null"
        variant="danger"
      >
        {{ apiError }}
      </b-alert>
    </div>
  </b-col>
</template>

<script>
import {
  BFormInput,
  BFormRadioGroup,
  BFormRadio,
  BSpinner,
  BAlert,
  BFormGroup,
  BCol,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import {
  ValidationProvider,
} from 'vee-validate'
import {
  required,
} from '@validations'
import TitleInputStep from '@/components/TitleInputStep.vue'

export default {
  name: 'AssessmentQuestion',
  components: {
    TitleInputStep,
    BFormInput,
    BFormRadioGroup,
    BFormRadio,
    BSpinner,
    BAlert,
    ValidationProvider,
    vSelect,
    BFormGroup,
    BCol,
  },
  props: {
    assessmentId: {
      type: String,
      required: true,
    },
    questionId: {
      type: String,
      required: false,
      default: null,
    },
    question: {
      type: Object,
      required: false,
      default: null,
    },
    isSubmitting: {
      type: Boolean,
      required: true,
    },
    titleStyle: {
      type: String,
      required: false,
      default: 'large',
    },
  },
  data() {
    return {
      apiError: null,
      remoteQuestion: null,
      answer: null,
      required,
    }
  },
  computed: {
    finalQuestion() {
      if (this.questionId != null) {
        return this.remoteQuestion
      }

      return this.question
    },
    questionRenderType() {
      if (this.finalQuestion === null) {
        return ''
      }

      const { responseConfig } = this.finalQuestion
      const responseType = responseConfig.allowedResponseType
      const validResponses = responseConfig.validResponses != null && responseConfig.validResponses.length > 0 ? responseConfig.validResponses : null

      if (responseType === 'regex') {
        return 'text-field'
      } else if (validResponses !== null && validResponses.length > 0) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.finalQuestion.hasOwnProperty('renderConfig') && this.finalQuestion.renderConfig.renderType === 'select expanded') {
          return 'radio-group'
        }

        return 'picker'
      }

      return 'unsupported'
    },
    gridWidth() {
      const q = this.finalQuestion
      if (q != null && q.renderConfig != null && q.renderConfig.gridWidth != null) {
        return +q.renderConfig.gridWidth
      }

      return this.questionRenderType === 'radio-group' ? 12 : 6
    },
    validationRules() {
      const rules = {
        required: true,
      }

      if (this.finalQuestion.responseConfig.allowedResponseType !== null && this.finalQuestion.responseConfig.allowedResponseType === 'regex') {
        rules.regex = this.finalQuestion.responseConfig.allowedResponseMetaData
      }

      return rules
    },
  },
  mounted() {
    if (this.questionId != null) {
      this.$http.get(`/assessments/${this.assessmentId}/questions/${this.questionId}`)
        .then(response => {
          this.remoteQuestion = response.data
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
        })
    }
  },
  methods: {
    getAnswer() {
      return {
        questionId: this.questionId != null ? this.questionId : this.question.uniqueId,
        userResponse: this.answer,
      }
    },
  },
}
</script>

<style lang="scss">
@import './src/@core/scss/vue/libs/vue-select.scss';
</style>
