<template>
  <b-row>
    <b-col>
      <h4
        v-if="title !== null"
        class="mb-1 heavy-title"
      >
        {{ title }}
      </h4>
      <slot />
    </b-col>
  </b-row>
</template>

<script>
import {
  BRow,
  BCol,
} from 'bootstrap-vue'
import VueI18n from 'vue-i18n'

export default {
  name: 'TitleInputStep',
  components: {
    BRow,
    BCol,
  },
  props: {
    title: {
      type: VueI18n.TranslateResult,
      required: true,
    },
  },
}
</script>

<style scoped>
.heavy-title {
  font-weight: 900;
}
</style>
