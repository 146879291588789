<template>
  <div class="m-2">
    <b-alert
      v-if="apiError != null"
      variant="danger"
    >
      {{ apiError }}
    </b-alert>
    <b-spinner
      v-else-if="assessment == null || (currentQuestionId == null && questions == null)"
      variant="secondary"
    />
    <div v-else>
      <b-progress
        v-if="assessment.type !== 'FormTemplate'"
        :value="percentComplete"
        max="100"
        class="progress-bar-success mb-3"
        variant="success"
      />

      <h3>{{ assessment.description }}</h3>
      <p>{{ assessment.directions }}</p>

      <validation-observer
        ref="assessmentForm"
        v-slot="{ invalid }"
      >
        <b-row>
          <assessment-question
            v-if="currentQuestionId != null"
            :key="currentQuestionId"
            ref="question0"
            :assessment-id="assessment.uniqueID"
            :question-id="currentQuestionId"
            :is-submitting="isSubmitting"
            class="my-3"
            @question-submitted="readyForNextQuestion($event)"
          />
          <assessment-question
            v-for="(question, index) in questions"
            v-else-if="questions != null"
            :key="`question${index}`"
            :ref="`question${index}`"
            :is-submitting="isSubmitting"
            :assessment-id="assessment.uniqueID"
            :question="question"
            title-style="small"
            class="my-1"
          />
        </b-row>

        <b-row>
          <b-col :class="allowFinishLater ? 'd-inline-flex justify-content-between' : 'text-right'">
            <b-button
              v-if="allowFinishLater"
              variant="outline-primary"
              :disabled="isSubmitting"
              @click="finishLater"
            >
              {{ $t('generic.finishLater') }}
            </b-button>
            <b-button
              variant="primary"
              type="submit"
              :disabled="invalid || isSubmitting"
              @click="validateQuestion"
            >
              <span class="align-middle">{{ isSubmitting ? $t('generic.submitting') : $t('generic.next') }}</span>
              <feather-icon
                icon="ChevronRightIcon"
                class="ml-50"
              />
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </div>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BSpinner,
  BAlert,
  BButton,
  BProgress,
} from 'bootstrap-vue'
import {
  ValidationObserver,
} from 'vee-validate'
import AssessmentQuestion from '@/components/assessments/AssessmentQuestion.vue'

export default {
  name: 'Assessment',
  components: {
    BRow,
    BCol,
    BSpinner,
    BAlert,
    BButton,
    BProgress,
    AssessmentQuestion,
    ValidationObserver,
  },
  props: {
    assessmentId: {
      type: String,
      required: true,
    },
    allowFinishLater: {
      type: Boolean,
      required: false,
      default: false,
    },
  },
  data() {
    return {
      assessment: null,
      questions: null,
      currentQuestionId: null,
      percentComplete: 0,
      apiError: null,
      isSubmitting: false,
    }
  },
  watch: {
    assessment(newVal) {
      if (newVal.type !== 'FormTemplate') {
        this.readyForNextQuestion(newVal)
      } else {
        this.fetchAllQuestions()
      }
    },
  },
  mounted() {
    this.fetchAssessment()
  },
  methods: {
    finishLater() {
      this.$router.replace({
        name: 'home',
        params: {
          forceActivityRefresh: true,
        },
      })
    },
    validateQuestion() {
      this.$refs.assessmentForm.validate().then(success => {
        if (success) {
          if (this.assessment.type !== 'FormTemplate') {
            this.submitQuestion()
          } else {
            this.submitAllQuestions()
          }
        }
      })
    },
    fetchAssessment() {
      this.$http.get(`/assessments/${this.assessmentId}`)
        .then(response => {
          this.assessment = response.data
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
        })
    },
    fetchAllQuestions() {
      this.$http.get(`/assessments/${this.assessmentId}/questions`)
        .then(response => {
          this.questions = response.data.questions
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
        })
    },
    submitQuestion() {
      this.isSubmitting = true

      this.$http.post(`/assessments/${this.assessmentId}/questions/${this.currentQuestionId}`, {
        userResponse: this.$refs.question0.getAnswer().userResponse,
      })
        .then(response => {
          this.readyForNextQuestion(response.data)
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
        })
        .finally(() => {
          this.isSubmitting = false
        })
    },
    submitAllQuestions() {
      if (this.questions == null || this.questions.length === 0) {
        return
      }

      this.isSubmitting = true

      const body = []
      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.questions.length; i++) {
        const questionComponent = this.$refs[`question${i}`][0]
        const answer = questionComponent.getAnswer()
        body.push(answer)
      }

      this.$http.post(`/assessments/${this.assessmentId}/questions`, body)
        .then(response => {
          if (response.data.canSubmit) {
            this.submitAssessment()
          } else {
            this.isSubmitting = false
          }
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
          this.isSubmitting = false
        })
    },
    submitAssessment() {
      this.isSubmitting = true

      this.$http.post(`/assessments/${this.assessmentId}`)
        .then(async () => {
          // we all hate this but we have to do it until we implement SSE
          await new Promise(r => setTimeout(r, 5000))

          this.$emit('assessment-complete')
        })
        .catch(() => {
          this.apiError = this.$t('generic.apiError')
          this.isSubmitting = false
        })
    },
    readyForNextQuestion(payload) {
      // Update completion percentage
      this.percentComplete = Math.round(payload.percentComplete)

      // Check what we need to do next
      const { nextQuestionId } = payload
      if (nextQuestionId != null) { // On to the next question!
        this.currentQuestionId = nextQuestionId
      } else if (payload.status !== 'Submitted' && payload.canSubmit) { // Check if assessment is ready to be submitted
        this.submitAssessment()
      }
    },
  },
}
</script>

<style scoped>

</style>
