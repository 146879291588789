<template>
  <b-card>
    <assessment
      :assessment-id="assessmentId"
      :allow-finish-later="allowFinishLater"
      @assessment-complete="assessmentComplete"
    />
  </b-card>
</template>

<script>
import Assessment from '@/components/assessments/Assessment.vue'
import {
  BCard,
} from 'bootstrap-vue'

export default {
  name: 'AssessmentView',
  components: {
    Assessment,
    BCard,
  },
  data() {
    return {
      assessmentId: this.$route.params.assessmentId,
      allowFinishLater: this.$route.params.allowFinishLater ?? true,
    }
  },
  methods: {
    assessmentComplete() {
      this.$router.replace({
        name: 'home',
        params: {
          forceActivityRefresh: true,
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
